
import { defineComponent } from 'vue';
import auth from '@/services/auth';

export default defineComponent({
  mounted() {
    auth.fetchLoginInfo();
  },
  methods: {
    handleGotoWeiXinAuth() {
      auth.login();
    },
  },
});
